import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/Seo';
import Typography from '../components/Typography/Typography';

export default function CookiePolicy() {
  return (
    <Layout>
      <SEO title="Cookie Policy" />
      <div style={{ maxWidth: 800, margin: '40px auto', padding: 10 }}>
        <Typography variant="span">Effective as of: 2022-03-10 21:37:44</Typography>
        <Typography variant="h2" align="center">
          COOKIE POLICY
        </Typography>
        <Typography variant="p">
          In this Cookie Policy we will provide you with detailed information on how EXPERTS CIRCLE
          LTD (hereinafter - the &quot;we&quot; or &quot;our&quot;), shall undertake to ensure the
          security of personal information and the protection of rights of the visitors and users of
          the websites (hereinafter - the &quot;Visitors&quot;, &quot;You&quot;) while you use our
          websites including but not limited to https://expertscircle.io (hereinafter - the
          &quot;Site&quot;) and the content on it.
        </Typography>
      

        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="h3" upperCase fontBold>
            1. What is a cookie?
          </Typography>
          <Typography variant="p">
            A cookie is a small file placed onto your device that enables our Site features and
            functionality. For example, cookies can enable us to identify your device and secure
            your access to the Site. Cookies also allow the Site to remember information about your
            browsing on the Site for a while and to recognize you the next time you visit the Site.
            All this allows us to give you the opportunity to use the Site comfortably and to make
            the Site even more user-friendly.
          </Typography>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="h3" upperCase fontBold>
            2. Why do we use cookies?
          </Typography>
          <Typography variant="p">We use cookies for the following main purposes:</Typography>

          <ul>
            <li>
              <Typography variant="p">
                To ensure efficient and safe functioning of the Site. We use cookies to enable and
                support our security features, and to help us detect malicious activity on our Site.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                To understand, improve, and research products, features, and services, including
                when you access our Site from other websites or devices such as your computer or
                your mobile device.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                To recognize the returning visitors of the Site. Cookies help us show you the right
                information and personalize your experience. Cookies also help avoiding
                re-registration or re-filling of the information by you each time you visit the
                Site.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                To analyze your habits so that the functioning of the Site would be convenient,
                efficient and would conform to your needs and expectations.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                To measure the flows of the information and data being sent to our Site. We use the
                cookies for accumulation of statistical data about the number of users of the Site
                and their use of the Site.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                {' '}
                For targeting and advertising. By using the cookies we may collect information so
                that only relevant content is displayed for the browser by creating different target
                groups. We may use cookies to show you relevant advertising both on and off our
                Site.
              </Typography>
            </li>
          </ul>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="h3" upperCase fontBold>
            3. What cookies do we use?
          </Typography>
          <Typography variant="p">
            Each time you visit our Site, the long-term (persistent) cookies may be created, which
            stay in your browser after you sign-up and will be read by us when you return to our
            Site and not deleted after you finish browsing our Site, and the short-term (session)
            cookies, which expire or are deleted after you finish browsing our Site (i.e. they
            usually last during the current visit to our Site or browsing session).
          </Typography>

          <Typography variant="p">Cookies used by the Company:</Typography>
          <ul>
            <li>
              <Typography variant="p">
                Strictly required or necessary cookies. These cookies are required for the operation
                of our Site. They include, for example, cookies that enable storage of information
                filled by you during the browsing session, enable you to log into secure areas of
                our Site. Without these cookies operation of the Site would be impossible or its
                functioning may be severely affected.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                Preferences cookies. These improve the functional performance of our Site and make
                it easier for you to use. These cookies remember the settings selected by the
                Visitors (for example, the settings of language or currency). With the use of these
                cookies, the Visitors may avoid the changes of settings during each visit of the
                Site. These cookies also remember changes made by you in the Site (for example, in
                case you leave comment on the Site). These cookies do not track your behavior in
                other websites.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                Analytics and Statistics. These cookies show us if the Visitor has visited our Site
                before. The analytic cookies allow us to recognize and count the number of users of
                our website and see how such users navigate through our Site. We also use cookies to
                understand, improve, and research products, features, and services. For instance,
                analytical cookies may show us, which websites are visited more frequently, help us
                to record dysfunctionalities of the Site, etc.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                Marketing and Retargeting. These cookies are usually set by our marketing and
                advertising partners. They may be used by them to build a profile of your interest
                and later show you relevant ads. If you do not allow these cookies you will not
                experience targeted ads for your interests.
              </Typography>
            </li>
          </ul>
          <Typography variant="p">Third-party cookies:</Typography>
          <ul>
            <li>
              <Typography variant="p">
                Other third-party cookies - in some web pages of our Site, the other entities (for
                example, social networks) may also use their own anonymous cookies designed so that
                the programs or applications developed by them would suit your needs. Due to the
                specific features of the functioning of the cookies, our Site does not have access
                to the information transmitted by these cookies, likewise other entities do not have
                access to the information collected by the cookies set by us.
              </Typography>
            </li>
            <li>
              <Typography variant="p">
                We use Google Analytics, a web analysis service provided by Google, Inc (hereinafter
                referred to as &quot;Google&quot;). The information collected by Google Analytics is
                transmitted to and stored with Google. Google may transmit the information collected
                by Google Analytics to the third parties as required by the law or when those third
                parties process the information in the name of Google. We recommend consulting the
                Google Privacy and Cookies Policy on a separate and regular basis.
              </Typography>
            </li>
          </ul>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="h3" upperCase fontBold>
            4. How to refuse or block cookies?
          </Typography>
          <Typography variant="p">
            Many web browsers are set so that they would automatically accept all cookies.
          </Typography>
          <Typography variant="p">
            You may refuse and make a preference which cookies you allow by choosing it in our
            Cookie Consent Banner. Also all the cookies will be set if you accept all by clicking
            &quot;Accept All&quot; on our Cookie Consent Banner.
          </Typography>
          <Typography variant="p">
            The Visitors may, at their discretion, manage, block or delete cookies, if the settings
            of their browser or device enable them to do so. Nevertheless, if you refuse or block
            the cookies or other similar technologies, some functions of the Site may be
            inaccessible to you or they may operate not properly.
          </Typography>
          <Typography variant="p">
            We draw your attention that necessary cookies are critical for functioning of our Site,
            and in case of your objections, some features of the Site may not work or may not work
            properly.
          </Typography>
          <Typography variant="p">
            You may require that we delete all the data about you, as collected and processed with
            the help of the cookies, by contacting to the email address help@expertscircle.io.
          </Typography>
          <Typography variant="p">
            You can opt out of Google Analytics without affecting how you visit our Site. For more
            information on opting out of being tracked by Google Analytics across all websites you
            use, visit this Google page: https://tools.google.com/dlpage/gaoptout.
          </Typography>
          <Typography variant="p">
            You may find more information about how to delete cookies, as well as the other useful
            information related to the use of the cookies, on the website
            http://www.allaboutcookies.org/.
          </Typography>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="h3" upperCase fontBold>
            5. Do we update Cookie Policy?
          </Typography>
          <Typography variant="p">
            This Cookie Policy may be updated by us from time to time. We will inform you about the
            updates, by providing the new version of the Cookie Policy. For this reason, we
            recommend you periodically visit our Site, where you will always find the latest version
            of this Cookie Policy. This Policy was created with the help of the{' '}
            <a
              style={{ textDecoration: 'none', color: 'inherit' }}
              href="https://termshub.io?utm_source=referral&amp;utm_medium=generated_documents&amp;utm_campaign=referral_documents&amp;utm_content=cp_th_text"
              target="_blank"
              rel="noreferrer"
            >
              TermsHub.io
            </a>
            and the
            <a
              style={{ textDecoration: 'none', color: 'inherit' }}
              href="https://termshub.io/cookie-policy?utm_source=referral&amp;utm_medium=generated_documents&amp;utm_campaign=referral_documents&amp;utm_content=cp_th_text"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
            Generator.
          </Typography>
          <Typography variant="p">
            This Cookie Policy shall be applied from the date of announcement of it on the Site.
          </Typography>
        </div>
      </div>
    </Layout>
  );
}
